import { theme, useResponsive } from "@product/scmp-sdk";
import chunk from "lodash/chunk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { chinaScienceDiscoveryModuleQueueQuery$key } from "scmp-app/queries/__generated__/chinaScienceDiscoveryModuleQueueQuery.graphql";

import {
  Container,
  MoreLink,
  RestItems,
  StyledContentItemHomeSecondary,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  TabletItems,
  Title,
  TopItems,
} from "./styles";

type Props = {
  className?: string;
  reference: chinaScienceDiscoveryModuleQueueQuery$key;
};

export const ChinaScienceDiscoveryModule: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment chinaScienceDiscoveryModuleQueueQuery on Query {
        chinaScienceDiscoveryModuleQueue: queue(filter: { name: "section_top_328475" }) {
          items(first: 6) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const Items = data?.chinaScienceDiscoveryModuleQueue?.items?.edges ?? [];
  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), false);
  const currentPageType = useCurrentPageType();

  if (Items?.length === 0) return null;

  const items = isTabletUp ? Items.slice(0, 5) : Items;
  const chunkItems = chunk(items, 2);
  const topItems = items.slice(0, 2);
  const restItems = items.slice(2);

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.LatestChinaScience,
        pgtype: currentPageType,
      }}
    >
      <Container className={className}>
        <Title>LATEST CHINA SCIENCE NEWS</Title>

        <TabletItems>
          <TopItems>
            {topItems.map(({ node }) => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                reference={node}
                withSummary={false}
                withImage
              />
            ))}
          </TopItems>
          <RestItems>
            {restItems.map(({ node }) => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                reference={node}
                withSummary={false}
                withImage
              />
            ))}
          </RestItems>
        </TabletItems>

        <SwiperContainer>
          <StyledSwiper
            breakpoints={{
              768: {
                direction: "vertical",
                slidesPerView: 5,
                spaceBetween: 16,
              },
            }}
            cssMode={false}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={16}
          >
            {chunkItems.map((item, index) => (
              <StyledSwiperSlide key={index}>
                {item.map(({ node }) => (
                  <StyledContentItemHomeSecondary
                    key={node.entityId}
                    reference={node}
                    withComment
                    withImage
                  />
                ))}
              </StyledSwiperSlide>
            ))}
          </StyledSwiper>
        </SwiperContainer>
        <MoreLink pathname="/news/china">MORE CHINA SCIENCE NEWS</MoreLink>
      </Container>
    </BaseLinkContextProvider>
  );
};

ChinaScienceDiscoveryModule.displayName = "ChinaScienceDiscoveryModule";
